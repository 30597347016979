@import "styles";

.delete-team-modal {
  max-width: 616px;

  .modal-header {
    text-align: center;
  }
}


