@import "styles";

.organization-coaches {
  overflow-y: scroll;

  &__header {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 32px;

    &__add-button {
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;
    }
  }
}
