@import "styles";

.empty-users {
  display: flex;
  justify-content: center;

  &__image {
    margin-top: 5%;
  }
}
