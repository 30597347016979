@import "styles";

.organization-table-item {
  display: grid;
  grid-template-columns: 1.5fr 1fr 2fr;
  justify-content: space-between;
  align-items: center;
  padding: 18px 32px;
  border-bottom: $container-border;
  cursor: pointer;

  &--active {
    background-color: $hover-select;
  }

  .name {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: $white;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pin {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: $white;
  }

  .actions {
    visibility: visible;
    display: flex;
    justify-content: flex-end;
    gap: 24px;
  }

  &:hover {
    background-color: $hover;

    .actions {
      visibility: visible;
    }
  }
}
