@import "styles";

.progress-mode-body {
  display: grid;
  grid-template-columns: 6fr 4fr;

  &__chart {
    padding: 32px;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__user {
      display: flex;
      gap: 8px;
      align-items: center;
      color: $grayscale-black;
      font-size: 19px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.4px;

      :first-child {
        width: 32px;
        height: 32px;
      }
    }

    &__description {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: $grayscale-gray;

      &__labels {
        display: flex;
        gap: 32px;
        font-weight: 600;

        &__today {
          display: flex;
          align-items: center;
          gap: 8px;

          div {
            height: 2px;
            width: 20px;
            background-color: $orange;
            border-radius: 1000px;
          }
        }

        &__yesterday {
          display: flex;
          align-items: center;
          gap: 8px;

          div {
            height: 2px;
            width: 20px;
            background-color: $grayscale-divider;
            border-radius: 1000px;
          }
        }
      }
    }

    &__chart {
      height: 100%;
      width: 100%;
      position: relative;
    }
  }

  &__widget {
    display: grid;
    border-left: 1px solid $platinum;

    &__item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;

      &:not(:last-child) {
        border-bottom: 1px solid $platinum;
      }

      &__title {
        color: $grayscale-gray;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.3px;
      }

      &__value {
        color: $grayscale-black;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.3px;
      }
    }
  }
}
