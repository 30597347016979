@import "styles";

.empty-item-container {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 32px;
  justify-content: space-between;

  &__image {
    width: 178px;
  }

  &__text {
    margin-top: 30px;
    font-size: 16px;
    //font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $spanish-grey;
  }
}
