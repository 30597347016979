@import "styles";

.coach-athlete-table-item {
  display: grid;
  grid-template-columns: 2.5fr 3fr 2fr 0.5fr;
  justify-content: space-between;
  align-items: center;
  padding: 18px 32px;
  border-bottom: $container-border;

  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: $white;
  width: 100%;


  &--disabled{
    opacity: 0.25;
    //filter: blur(1px);
    //pointer-events: none;
  }

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pin {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: start;
    color: $white;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions {
    visibility: visible;
    display: flex;
    justify-content: flex-end;
    // gap: 24px;
  }

  &:hover {
    background-color: $hover;

    .actions {
      visibility: visible;
    }
  }
}
