@import "styles";

.run {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: $spanish-grey;
  padding: 4px 0px;

  &:hover {
    background-color: $hover;
  }

  div {
    width: 100%;

    &:nth-child(2) {
      text-align: center;
    }

    &:last-child {
      text-align: right;

      img {
        cursor: pointer;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    &__graph {
      color: $green;

      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
