@import "styles";

.item-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 32px;
  font-weight: 450;
  line-height: 35px;
  color: $white;
}
