@import "styles";

.modal-footer {
  margin-top: 32px;
  display: flex;
  gap: 16px;

  justify-content: center;

  button {
    width: 40%;
    padding: 18px;
  }
}
