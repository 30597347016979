@import "styles";

.assessment {
  border-bottom: $container-border;
  padding: 16px;

  &__header {
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      right: 0px;
      top: 20px;
      border: 7px solid transparent;
      border-color: $green transparent transparent transparent;
    }

    &--active::after {
      transform: rotate(180deg);
      top: 6px;
    }

    &--placeholder {
      font-size: 14px;
      color: #a8a8a8;
    }

    &__title {
      color: $green;
    }

    &__date {
      color: $green;
      font-size: 12px;
    }
  }

  &__body {
    margin-top: 16px;

    &__statistic {
      margin-top: 16px;

      &__item {
        margin-bottom: 4px;
        color: $white;
        display: flex;
        justify-content: space-between;

        div {
          width: 100%;

          &:nth-child(2) {
            text-align: center;
          }
        }
      }
    }
  }

  .run {
    // border-bottom: $container-border;
    // border-bottom: 1px solid rgba(153, 153, 153, 0.2);
    // background-color: blue;
    &:not(:last-child) {
    }
  }
}
