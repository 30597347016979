@import "styles";

.sidebar {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 225px;

  &__link {
    padding: 18px 32px;
    gap: 24px;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;

    &:hover,
    &.active {
      background-color: $hover;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 4px;
        height: 100%;
        left: 0;
        bottom: 0;
        background-color: $green;
      }
    }

    &__title {
      color: $white;
      font-size: 16px;
      line-height: 18px;
    }

    &--denied{
      opacity: 20%;
      &__title {
        color: $white;
      }
    }
  }
}
