@import "styles";

.coach-athletes-list-modal {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__body {
    height: 50vh;
    overflow-y: scroll;

    .coach-table-item {
      display: grid;
      grid-template-columns: 3fr 2fr 1fr;
      justify-content: space-between;
      align-items: center;
      padding: 18px 32px;
      border-bottom: $container-border;
      cursor: pointer;

      .name {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        color: $white;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .pin {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: $white;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .actions {
        visibility: hidden;
        display: flex;
        justify-content: flex-end;
        gap: 24px;
      }

      &:hover {
        background-color: $hover;

        .actions {
          visibility: visible;
        }
      }
    }
  }
}
