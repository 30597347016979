@import "styles";

.empty-users {
  display: flex;
  justify-content: center;

  &__image {
    margin-top: 5%;
  }
}

.client-management-active-users {
  // height: 100vh;
  overflow-y: scroll;
  display: grid;
  grid-template-rows: 1fr;
  // height: 100%;

  // &::-webkit-scrollbar {
  //   width: 0.5em;
  // }

  // &::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: $eerie-black;
  //   outline: 1px solid $green;
  // }

  // &::-webkit-scrollbar-corner {
  //   background-color: $eerie-black;
  // }

  &__item {
    cursor: pointer;

    &:hover {
      background-color: $hover;
    }

    display: grid;
    grid-template-columns: 1fr 1.5fr 0.5fr 1.5fr;
    align-items: center;
    border-bottom: $container-border;
    padding: 10px 30px;

    &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      color: $white;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow: hidden;

      &:nth-child(3) {
        text-align: center;
      }
    }

    &__actions {
      display: flex;
      gap: 12px;
      justify-content: flex-end;
      align-items: center;

      .info-button {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }
  }
}
