@import "styles";

.tabs {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-radius: 14px;
  border: $container-border;
  color: $white;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  background-color: $input;
  overflow: hidden;

  cursor: pointer;

  .tab-item {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    //height: 100%;

    &--active {
      color: $eerie-black;
      background-color: $green;
    }

    &--loading {
      background-color: $spanish-grey;
    }
  }
}
