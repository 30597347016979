@import "styles";

.run-chart {
  padding: 16px;
  margin: 16px 0px;

  &__container {
    // position: absolute;
    // width: 90%;
    // height: 50%;
    // top: 0px;
    // bottom: 0px;

    height: 250px;
    margin-bottom: 16px;
  }

  &__details {

    &__item {
      padding: 6px 24px;
      display: flex;

      justify-content: space-between;
      border-top: $container-border;
      color: $green;

      span:first-child {
        color: white;
      }
    }

    // background-color: red;
  }
}

.point-symbol {
}

.point-symbol:hover {
  visibility: visible;
}

.point {
  // visibility: hidden;

  &:hover {
    visibility: visible;
  }

  .point-tooltip {
    display: flex;
    gap: 32px;
  }

  .point-symbol2 {
    opacity: 0.5;
  }
}
