@import "styles";

.form-select-container {
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: $container-border;
  height: 52px;
  box-sizing: border-box;
  color: $white;
  //font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  background-color: $input;
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;

  &--active {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &--error {
    border: 1px solid #cc5951;
    background-color: $red-input;
  }

  .selected-text {
    &--placeholder {
      color: #a8a8a8;
    }

    &__icon{
      position: absolute;
      right: 16px;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);

      &--active {
        //transform: rotate(180deg);
        bottom: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .select-options {
    position: absolute;
    left: -1px;
    top: 52px;
    width: calc(100% + 2px);
    padding: 0;
    z-index: 100;
    background-color: $input;
    max-height: 200px;
    overflow: scroll;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    border: $container-border;

    &--show {
      opacity: 1;
      visibility: visible;
    }

    &--hide {
      min-height: 0;
      opacity: 0;
      visibility: hidden;
    }

    &__option {
      list-style-type: none;
      padding: 6px 20px;
      position: relative;
      border-bottom: $container-border;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: $hover;
      }

      &__icon {
        position: absolute;
        right: 4px;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }

  &__error-message {
    position: absolute;
    color: #e03c4c;
    font-size: 13px;
    line-height: 23px;
    top: 52px;
    left: 0;
  }
}