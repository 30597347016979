@import "styles";

.overview-teams {
  &__header {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 32px 32px 60px 32px;

    .title {
      font-size: 19px;
      line-height: 24px;
      letter-spacing: 0.4px;
      font-weight: 700;
      color: $eerie-black;
    }

    .action {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      font-weight: 600;
      color: $green;
    }
  }

  &__body {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-width: 100%;
      padding: 0 32px 18px 32px;

      .left-side {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        font-weight: 600;
        color: $spanish-grey;
      }
    }
  }
}
