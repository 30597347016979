@import "styles";

.update-plan-modal {
  max-width: 454px;
  min-width: 400px;

  &__title{
    color: $white;
    margin-bottom: 8px;
  }
  &__subtitle{
    color: $spanish-grey;
    padding-bottom: 32px;
  }
  button{
    width: 100%;

  }
}


