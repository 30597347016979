@import "styles";

.info-list-item {
  height: 52px;
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: $input;
  color: $spanish-grey;

  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 4px;

    &--primary {
      color: $sky-blue;
    }

    &--subtext {
      font-size: 13px;
    }
  }
}