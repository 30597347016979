@import "styles";

.new-request-header {
  .actions {
    display: flex;
    gap: 16px;

    .add-button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }

    .approve-button {
      background-color: transparent;
      display: flex;
      gap: 10px;
      align-items: center;
      border: 1px solid $platinum;

      &__square {
        height: 16px;
        width: 16px;
        border-radius: 6px;
        border: 2px solid $orange;
      }

      &__text {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.5px;
        color: $eerie-black;
      }
    }
  }
}
