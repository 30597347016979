@import "styles";

.role-picker {
  height: 31px;
  border-radius: 4px;
  max-width: 140px;
  //padding: 5px;

  .tab-item {
  }
}
