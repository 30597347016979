@import "styles";

.progress-top-item {
  padding: 8px 26px;
  // height: 100%;
  height: max-content;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  display: flex;
  box-sizing: border-box;

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1500px;
    margin-bottom: 16px;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 2px solid rgba(125, 133, 146, 0.202032);
    padding: 2px;

    &--active {
      border: 2px solid #ff8400;
      padding: 0px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 1500px;
    }
  }

  &__name {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: $eerie-black;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 3px;
    margin-top: 10px;
  }

  &__phone {
    font-size: 13px;
    line-height: 21px;
    color: $eerie-black;
    white-space: nowrap;
  }
}

// .overview-top-item {
//   padding: 24px 32px;
//   align-items: center;
//   flex-direction: column;
//   display: flex;
//   cursor: pointer;

//   .title {
//     font-weight: 700;
//     font-size: 19px;
//     line-height: 24px;
//     letter-spacing: 0.4px;
//     color: $spanish-grey;
//     margin-bottom: 12px;
//     white-space: nowrap;
//     &--active {
//       color: $orange;
//     }
//   }

//   .value {
//     font-weight: 700;
//     line-height: 50px;
//     font-size: 40px;
//     letter-spacing: 1px;
//     color: $eerie-black;
//     &--active {
//       color: $orange;
//     }
//   }
// }
