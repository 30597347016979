@import "sizes";

.container {
  margin: 0 auto;
  min-width: $min-container-width;
  max-width: $max-container-width;

  &__fluid {
    max-width: 100%;
  }
}

.row {
  display: flex;
  flex-wrap: nowrap;
}

.column {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.col-1 {
  max-width: calc(100% / 12);
}

.col-2 {
  max-width: calc(100% / 12 * 2);
}

.col-3 {
  max-width: calc(100% / 12 * 3);
}

.col-4 {
  max-width: calc(100% / 12 * 4);
}

.col-5 {
  max-width: calc(100% / 12 * 5);
}

.col-6 {
  max-width: calc(100% / 2);
}

.col-7 {
  max-width: calc(100% / 12 * 7);
}

.col-8 {
  max-width: calc(100% / 12 * 8);
}

.col-9 {
  max-width: calc(100% / 12 * 9);
}

.col-10 {
  max-width: calc(100% / 12 * 10);
}

.col-11 {
  max-width: calc(100% / 12 * 11);
}

.col-12 {
  max-width: 100%;
}
