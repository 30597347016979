@import "styles";

.confirmation-modal {
  width: 20%;

  &__header {
    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      color: $white;
      font-size: 18px;
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
    }
  }

  &__body {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: $secondary;

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 4px;
  }
}
