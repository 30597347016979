@import "styles";

.modal {
  position: relative;
  z-index: 9999;
  min-width: 40%;
  max-width: 60%;
  background-color: $container;
  border-radius: 4px;
  padding: 32px;

  @include box-shadow(0px, 0px, 10px, 5px, rgba($black, 0.15));
}
