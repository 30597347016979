.overlay {
  display: flex;
  position: absolute;
  z-index: 998;
  width: 100%;
  height: 100vh;
  background-color: rgba(#464646, 0.5);
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
}
