@import "styles";

.form-button {
  padding: 12px;
  background-color: $green;
  color: $background;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  font-family: $regular-font-family;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &--outline {
    background-color: $background;
    border: $border;
    color: $icicle-grey;
  }

  &--decline {
    color: $white;
    background-color: $red;
  }

  &--cancel {
    color: $red;
    border: 1px solid $red;
    background-color: transparent;
  }

  &--disabled {
    background-color: $platinum;
    color: $white;
  }

  &--text {
    background-color: $background;
    color: $white;
  }

  &--link {
    background-color: $input;
    color: $white;
    text-decoration: underline;
    font-size: 1rem;
    font-family: $main-font-family;
    font-weight: 400;
    padding: 17px;
    width: 100%;
  }

  &--hidden {
    display: none;
  }
}
