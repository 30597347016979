@import "styles";

.checkbox-wrapper {
  input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.6em;
    height: 1.6em;
    border-radius: 5px;
    border: 2px solid $green;
    outline: none;
    cursor: pointer;
  }

  input.checked {
    background-color: $white;
    position: relative;
  }

  input.checked::before {
    content: "\2714";
    font-size: 1.2em;
    color: $green;
    position: absolute;
    right: 2px;
  }
}
