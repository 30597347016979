@import "styles";

*:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #a8a8a8;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #a8a8a8;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #a8a8a8;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a8a8a8;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.form-input {
  position: relative;

  &__input {
    width: 100%;
    border: $container-border;
    padding: 16px;
    box-sizing: border-box;
    color: $white;
    //font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    background-color: $input;
    border-radius: 4px;

    &--error {
      border: 1px solid #cc5951;
      background-color: $red-input;
    }

    &--disabled {
      background-color: $input;
      opacity: 0.6;
    }
  }

  &__error-message {
    position: absolute;
    color: #e03c4c;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.30000001192092896px;
  }

  &__password-icon {
    position: absolute;
    bottom: 18px;
    right: 20px;
  }
}
