@import "styles";

.subscriptions-list {
  background-color: $container;
  border: 1px solid #99999933;
  border-top: none;
  display: flex;
  flex-direction: column;
  border-radius: 4px;

  &__tabs {
    border-bottom: 1px solid $eerie-black;
    border-top: 1px solid #99999933;
    border-radius: 4px;
    position: sticky;
    top: -1px;
    background: inherit;
    z-index: 10;
    display: flex;
    justify-content: space-between;

    &__button {
      background-color: transparent;
      border: none;
      color: $white;
      padding: 16px;
      cursor: pointer;

      &--active {
        border-bottom: 1px solid $green;
      }
    }
  }

  &__list {
    flex-grow: 1;

    &--header {
      position: sticky;
      margin-top: 29px;
      display: grid;
      grid-template-columns: 2fr 0.5fr 2.5fr 2fr 1.5fr 1.5fr 1.5fr;
      justify-content: space-between;
      align-items: center;
      padding: 18px 32px;
      border-bottom: $container-border;
      cursor: pointer;
      color: $spanish-grey;
      background-color: #1e1e1e;
      top: 57px;
      z-index: 200;
    }
  }
}


.subscription-requests-list {
  &__list {
    //overflow-y: auto;
    flex-grow: 1;

    &--header {
      margin-top: 29px;
      display: grid;
      grid-template-columns: 2fr 0.5fr 2.5fr 2fr 1.5fr 1.5fr 1.5fr 1.5fr;
      justify-content: space-between;
      align-items: center;
      padding: 18px 32px;
      border-bottom: $container-border;
      cursor: pointer;
      color: $spanish-grey;

      background-color: #1e1e1e;
      top: 57px;
      z-index: 200;
      position: sticky;
    }
  }
}