// ----- GRID ----- //

$min-container-width: 320px;
$max-container-width: 1440px;
$gutter: 10px;

// ----- LAYOUT ----- //

$small-screen-breakpoint: 600px;
$medium-screen-breakpoint: 1024px;
$large-screen-breakpoint: 1440px;

$spacing-xxxlarge: 48px;
$spacing-xxlarge: 40px;
$spacing-xlarge: 32px;
$spacing-large: 24px;
$spacing-medium: 20px;
$spacing-small: 16px;
$spacing-xsmall: 12px;
$spacing-xxsmall: 8px;
$spacing-xxxsmall: 4px;
