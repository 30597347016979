@import "styles";

.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100% !important;
  width: 100%;

  &__image {
    width: 178px;
  }

  div {
    margin-top: 30px;
    font-size: 16px;
    //font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $spanish-grey;
  }
}
