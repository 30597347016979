@import "styles";

.page {
  display: grid;
  grid-template-columns: 1.5fr 8.5fr;
  grid-template-rows: 1.2fr 8fr 0.4fr;
  grid-template-areas:
    "sidebar header"
    "sidebar main"
    "sidebar footer";
  height: 100vh;
}

header {
  grid-area: header;
  padding: 30px;
  background-color: $background;
}

main {
  grid-column: main;
  background-color: $background;
  width: 100%;
  overflow: auto;
  padding: 0 30px;
}

aside {
  display: grid;
  grid-template-rows: 1.6fr 8.5fr 0.4fr;
  background-color: $background;
  grid-row: sidebar;
  width: 100%;

  .page__sidebar__logo {
    padding: 35px;
    width: 100%;
  }
}

footer {
  background: #e2deed;
  background-color: $background;
  width: 100%;
}

* {
  box-sizing: border-box;
}
