@import "styles";

.item-container {
  background-color: $container;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(9, 125, 147, 0.05);
  border: $container-border;

  &--active {
    border: $border;
  }
}
