@import "styles";

.overview-subscription-item {
  //max-width: 177px;
  padding: 22px 24px;
  justify-content: center;
  flex-direction: column;
  display: flex;
  cursor: pointer;
  gap: 12px;

  .title {
    color: $spanish-grey;
    font-size: 16px;

    .title-text {
      font-size: 24px;
      font-weight: 450;
      line-height: 35px;
      letter-spacing: 0;
      margin-bottom: 6px;
      color: $white;

      //width: 90px;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }


  .value {
    display: flex;
    align-items: center;
    color: $spanish-grey;
    font-size: 16px;
    gap: 12px;

    .value-text {
      font-size: 32px;
      font-weight: 450;
      line-height: 35px;
      color: $green;
    }
  }
}
