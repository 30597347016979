@import "styles";

.request-body-new {
  display: grid;
  grid-template-columns: 3fr 1.5fr 1fr;
  align-items: center;
  border-bottom: $container-border;
  padding: 10px 0px;

  .name-column {
    overflow: hidden;

    div {
      &:first-child {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        color: $white;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:nth-child(2) {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        color: $white;
      }
    }
  }

  .date-column {
    font-size: 16px;
    line-height: 18px;
    color: $white;
  }

  .team-column {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: $white;
  }

  .actions-column {
    display: flex;
    gap: 18px;
    justify-content: flex-end;

    .approve-button {
      background: $green;
      display: flex;
      gap: 10px;
    }

    .decline-button {
      background: $red;
      display: flex;
      gap: 10px;
    }
  }
}
