@import "styles";

.modal-header {
  margin-bottom: 32px;

  span {
    font-size: 32px;
    font-weight: 450;
    line-height: 35px;
    color: $white;
  }
}
