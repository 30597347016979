@import "styles";

.personal-info-edit {
  padding: 32px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 32px;
    border-bottom: 1px solid $platinum;

    .user-info {
      display: flex;
      align-items: center;
      gap: 32px;

      &__edit {
        position: relative;

        &__avatar {
          width: 106px;
          height: 106px;
          border-style: dashed;
        }

        &__edit {
          position: absolute;
          right: 4px;
          background-color: $orange;
          display: inline-flex;
          align-items: center;
          padding: 5px;
          justify-content: center;
          border-radius: 100px;
        }
      }

      &__name {
        &__name {
          font-size: 22px;
          font-weight: 600;
          line-height: 28px;
        }

        &__email {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    .edit-button {
      color: $orange;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      text-decoration: underline;
    }
  }

  &__inputs {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
  }
}
