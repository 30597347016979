@import "styles";

.progress-chart {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
}

.point-tooltip {
  background-color: white;
  padding: 8px 16px;
  border: 1px solid $grayscale-gray;
  border-radius: 4px;
  z-index: 1000;
}

.point-symbol2 {
  opacity: 0.5;
}
