@import "styles";

.athlete-assessments-modal {
  width: 100%;

  &__assessments {
    height: 50vh;
    overflow: scroll;
    background-color: $input;
  }
}

@media (max-width: 1386px) {
  .athlete-assessments-modal {
    &__gears {
      //overflow: scroll;
    }
  }
}
