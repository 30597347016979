@import "styles";

.organization-athletes {
  overflow-y: scroll;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-width: 0;
  flex: 1;
  position: relative;

  &__placeholder-img {
    width: 100px;
    height: 100px;
  }

  &__body {
    position: absolute;
    inset: 100px 0 0 0;

    &__empty {
      margin-top: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 40px;

      img {
        width: 100px;
        height: 100px;
      }

      span {
        //font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.4px;
        text-align: center;
        color: $spanish-grey;
      }
    }
  }

  &__header {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 32px;

    &__add-button {
      display: flex;
      gap: 16px;
      justify-content: center;
      align-items: center;
    }
  }
}
