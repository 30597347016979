@import "styles";

.overview-table-item {
  display: flex;
  justify-content: space-between;
  padding: 18px 32px;
  border-top: 1px solid $platinum;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid $platinum;
  }
}
