@import "styles";

.upload-modal {
  &__text {
    text-align: start;
    color: white;

    span {
      color: $spanish-grey;
    }
  }

  &__body {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    gap: 32px;
    margin-top: $spacing-large;
    justify-content: space-evenly;

    &__button {
      color: $sky-blue;
    }
  }
}
