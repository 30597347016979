// $main-font-family: "Montserrat";
@font-face {
  font-family: "AntonRegular";
  src: url("../fonts/Anton-Regular.ttf") format("truetype-variations");
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/FuturaPTLight.ttf") format("truetype-variations");
  //font-weight: 500;
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/FuturaPTBook.ttf") format("truetype-variations");
  font-weight: 450;
}

$main-font-family: "Futura", sans-serif !default;
$regular-font-family: "AntonRegular";
// All the line heights are set according to the https://grtcalculator.com

// $headline-01: (
//   font-family: $main-font-family,
//   font-size: 36px,
//   line-height: 61px,
//   font-weight: 700,
//   letter-spacing: 0,
// );

// $headline-02: ();

// $headline-03: ();

// $body: (
//   font-family: $main-font-family,
//   font-size: 14px,
//   line-height: 24px,
//   font-weight: 400,
//   letter-spacing: 0,
// );

// $input: (
//   font-family: $main-font-family,
//   font-size: 14px,
//   line-height: 24px,
//   font-weight: 400,
//   letter-spacing: 0,
// );

// $button: (
//   font-family: $main-font-family,
//   font-size: 16px,
//   line-height: 27px,
//   font-weight: 600,
//   letter-spacing: 0,
// );

// $body-link: ();

// $body-list-item: ();
