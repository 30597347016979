@import "styles";

.select-add-athlete {
  min-width: 441px;

  button {
    width: 100%;
    padding: 18px;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  .modal {
    width: 100px;
  }

  &__close-btn {
    color: $icicle-grey;
  }
}
