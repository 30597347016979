@import "styles";

.user-avatar {
  border: 2px solid rgba(125, 133, 146, 0.2);
  width: 50px;
  height: 50px;

  padding: 2px;

  border-radius: 500px;

  &--active {
    border: 2px solid $orange;
    padding: 0px;
  }
}
