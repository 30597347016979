@import "styles";

.new-request-header {
  padding-bottom: 35px;

  .approve-button {
    background-color: transparent;
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid $platinum;
  }
}
