@mixin content-center($padding) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  min-height: 100%;
  padding: $padding;
}

@mixin to-props($token) {
  @each $prop, $value in $token {
    #{$prop}: $value;
  }
}

@mixin box-shadow($top, $left, $blur, $size, $color) {
  -webkit-box-shadow: $top $left $blur $size $color;
  -moz-box-shadow: $top $left $blur $size $color;
  box-shadow: $top $left $blur $size $color;
}
