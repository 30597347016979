@import "styles";

.picker-tabs {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-radius: 14px;
  border: $eerie-black;
  //color: $white;
  color: $eerie-black;
  font-family: $regular-font-family;
  font-size: 18px;
  font-weight: 400;
  //line-height: 22px;
  background-color: $spanish-grey;
  overflow: hidden;
  cursor: pointer;

  .tab-item {
    padding: 6.5px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &--active {
      color: $eerie-black;
      background-color: $green;
    }

    &--loading {
      background-color: $spanish-grey;
    }
  }

  .tab-item:not(:last-child) {
    border-right: 1px solid $eerie-black;
  }
}
