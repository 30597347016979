@import "styles";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left {
    color: $white;
    font-weight: 450;
    letter-spacing: 0.3px;
    font-size: 32px;
    line-height: 34px;
  }

  &__right {
    display: flex;
    gap: 32px;
    align-items: center;

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 26px;
    }

    &__user {
      display: flex;
      gap: 14px;
      align-items: center;

      &__name {
        font-size: 16px;
        line-height: 18px;
        text-align: right;
        color: $white;
      }

      &__sign-icon {
        fill: white;
        cursor: pointer;
      }
    }
  }
}
