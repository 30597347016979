@import "styles";

.athlete-status {
  padding: 5px 12px;
  border-radius: 8px;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.5px;
  color: $white;

  &--active {
    background-color: $green;
  }

  &--offline {
    background-color: $platinum;
    color: $spanish-grey;
  }

  &--pause {
    background-color: $orange-secondary;
  }
}
