@import "styles";

.change-subscription-modal {
  max-width: 548px;

  &__header {
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-top: $spacing-xxsmall;
    width: 100%;
    gap: 12px;

    &--blue {
      color: $sky-blue;
    }

    &--red {
      color: $red;
    }
  }

  &__list-item {
    height: 52px;
    padding: 4px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: $input;
    color: $spanish-grey;

    &__left {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      row-gap: 4px;
      //color:red

      &--primary {
        color: $sky-blue;
      }

      &--subtext {
        font-size: 13px;
      }
    }
  }
}
