@import "styles";

.overview-table-item-left-side {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: $eerie-black;
}

.overview-table-item-right-side {
  height: 24px;

  .right-side-initial {
    display: flex;
    align-items: center;
    gap: 10px;

    .persons-images {
      display: flex;
      align-items: center;

      &__image {
        border-radius: 50px;
        border: 3px solid $white;
        margin-left: -6px;
      }
    }
  }

  .right-side-secondary {
    display: none;
  }

  &__count {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: $spanish-grey;
  }
}

.overview-table-item:hover {
  background-color: $hover;

  .right-side-initial {
    display: none;
  }

  .right-side-secondary {
    display: block;
  }
}
