@import "styles";

.subscription-info-modal {
  &__header {
    text-align: left;
  }

  &__divider {
    border-top: 1px solid $eerie-black;
    border-bottom: none;
    border-left: none;
    border-right: none;
    margin: 32px 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-top: $spacing-xxsmall;
    width: 100%;
    gap: 12px;
    margin-bottom: 32px;

    &--blue {
      color: $sky-blue;
    }

    &--red {
      color: $red;
    }

    &--pending {
      &--text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        color: $green;
        padding-bottom: 12px;
      }
    }

    &--declined {
      &--text {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        color: $green;
        padding-bottom: 12px;


        &--status {
          color: $red;
        }
      }
    }
  }


}


