@import "styles";

.speed-unit-container {
  padding-top: 20px;

  .speed-unit-item {
    display: flex;
    align-items: center;
    gap: 25px;
    padding-left: 36px;
    border-bottom: 1px solid $platinum;

    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.2px;
      padding: 16px 0px;
    }
  }
}
