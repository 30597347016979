@import "styles";

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}

.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 1px;
  width: 28px;
  height: 28px;
  background-color: $white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(18px);
  background-color: $green;
}

.toggle-switch input[type="checkbox"]:checked + .switch {
}
