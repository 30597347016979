@import "styles";

.upload-modal {
  &__text {
    text-align: center;

    span {
      color: $secondary;
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    margin-top: $spacing-large;
    justify-content: space-evenly;
  }
}
