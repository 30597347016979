@import "styles";

.admin-all-organizations {
  overflow-y: scroll;

  &__header {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 32px;

    &__add-button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }
  }

  &__body {
    &__empty {
      margin-top: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 40px;

      img {
        width: 50%;
        height: 50%;
      }

      span {
        font-size: 19px;
        //font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.4px;
        text-align: center;
        color: $spanish-grey;
      }
    }
  }
}
