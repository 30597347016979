$eerie-black: #212121;
$orange: #ff8400;
$orange-secondary: #ffc444;
$white: #ffffff;
$green: #10ff7b;
$background: #101010;
$background-black: #121212;
$platinum: #e7e7e7;
$spanish-grey: #999999;
$black: #000;
$hover: rgba($white, 0.06);
$grayscale-gray: #9fa2b4;
$grayscale-black: #252733;
$grayscale-divider: #dfe0eb;
$sky-blue: #00D1FF;
$sky-blue-input: #1A2123;
$border: 1px solid $green;
$container-border: 1px solid rgba(153, 153, 153, 0.2);
$red: #dc5148;
$icicle-grey: #DDFDFE;
$red-input: rgba(220, 81, 72, 0.1);
$container: #171717;
$input: #1c1c1c;
$hover-select: rgba($green, 0.1);
// ----- Secondary Colors -----

$secondary: #464646;
$secondary-50: #a8a8a8;
$secondary-100: #717171;

// ----- Background Colors -----

// ----- Semantic Colors -----

$light-success: #f0f8ee;
$dark-success: #50a759;
$darker-success: #359f40;
$light-error: #fff0ef;
$dark-error: #cc5951;
$dark-warning: #ffa51f;

// ----- Others -----

//-----Combo 3A - Final Colors ------
$blue-50: #d2edf4;
$blue-100: #3cbfdd;
$blue-200: #405ca9;
$blue-300: #252861;

$pink-primary: #97268a;
$pink-50: #be41b0;

$violet-primary: #ecebfb;

$gray-primary: #f8f9fa;

$accent: #97268a;

$hover-highlight: #f5f3ff;
