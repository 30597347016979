@import "styles";

.subscriptions-table-item {
  display: grid;
  grid-template-columns: 2fr 0.5fr 2.5fr 2fr 1.5fr 1.5fr 1.5fr;
  justify-content: space-between;
  align-items: center;
  padding: 4px 32px;
  height: 58px;
  border-bottom: $container-border;
  cursor: pointer;

  .name {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: $white;
    width: 100%;
    //overflow: hidden;
    text-overflow: ellipsis;
  }

  .plan {
    margin-right: 16px;
  }

  .select-text-secondary {
    color: $spanish-grey;
  }

  &:hover {
    background-color: $hover;

    .actions {
      visibility: visible;
    }
  }
}
