@import "styles";

.admin-organization-info-modal {
  &__body {
    height: 36vh;
    overflow-y: scroll;
    // &::-webkit-scrollbar {
    //   width: 1em;
    // }

    // &::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // }

    // &::-webkit-scrollbar-thumb {
    //   background-color: $eerie-black;
    //   border: 1px solid $green;
    // }

    // &::-webkit-scrollbar-corner {
    //   background-color: $eerie-black;
    // }

    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      border-bottom: $container-border;
      padding: 10px 0px;

      &__text {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        color: $white;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;

        &:nth-child(2) {
          text-align: center;
        }
      }

      &__actions {
        display: flex;
        gap: 12px;
        justify-content: flex-end;
        align-items: center;

        .info-button {
          cursor: pointer;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
