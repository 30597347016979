@import "styles";

.subscriptions-request-table-item {
  display: grid;
  grid-template-columns: 2fr 0.5fr 2.5fr 2fr 1.5fr 1.5fr 1.5fr 1.5fr;
  justify-content: space-between;
  align-items: center;
  padding: 4px 32px;
  height: 58px;
  border-bottom: $container-border;
  cursor: pointer;

  .name {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: $white;
    width: 100%;
    //overflow: hidden;
    text-overflow: ellipsis;
  }

  .request-plan {
    margin-right: 16px;
    border-radius: 8px;
    //border: $container-border;
    height: 40px;
    box-sizing: border-box;
    color: $white;
    font-weight: 400;
    line-height: 22px;
    background-color: $sky-blue-input;
    display: flex;
    align-items: center;
    padding: 16px;
  }

  .select-text-secondary {
    color: $spanish-grey;
  }

  .actions-column {
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    .approve-button {
      background: $green;
      display: flex;
      height: 35px;
      width: 35px;
    }

    .decline-button {
      background: $red;
      display: flex;
      height: 35px;
      width: 35px;
    }
  }

  &:hover {
    background-color: $hover;

    .actions {
      visibility: visible;
    }
  }
}
