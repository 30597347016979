@import "styles";

.admin-coaches-list {
  overflow-y: scroll;
  height: 50%;

  &::-webkit-scrollbar {
    display: none;
  }

  &__header {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 32px;

    &__add-button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }
  }

  &__body {
    &__list {
      &__item {
        display: grid;
        grid-template-columns: 2fr 4fr;

        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        color: $white;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 18px 32px;
        border-bottom: $container-border;
        cursor: pointer;
        white-space: nowrap;

        :nth-child(1) {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        :nth-child(2) {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        :nth-child(3) {
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: end;
        }

        :nth-child(4) {
          display: flex;
          justify-content: flex-end;
        }

        // .actions {
        //   visibility: visible;
        //   display: flex;
        //   justify-content: flex-end;
        //   gap: 24px;
        // }

        &:hover {
          background-color: $hover;

          .actions {
            visibility: visible;
          }
        }

        &--selected {
          background-color: $hover-select;
        }
      }
    }
  }
}
