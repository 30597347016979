@import "styles";

.edit-athlete-modal {
  max-width: 400px;

  .make-coach-admin-header {
    text-align: center;
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: $spacing-xxsmall;
  }

  &__delete {
    margin-top: $spacing-xxsmall;
    display: flex;
    width: 100%;
    gap: 32px;

    &--blue {
      color: $sky-blue;
    }

    &--red {
      color: $red;
    }
  }
}
