@import "styles";

.overview-top-item {
  padding: 24px 32px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  cursor: pointer;

  .title {
    font-size: 32px;
    font-weight: 450;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 6px;
    color: $spanish-grey;

    &--active {
      color: $green;
    }
  }

  .value {
    font-size: 32px;
    font-weight: 450;
    line-height: 35px;
    color: $white;

    &--active {
      color: $green;
    }
  }
}
