/* You can add global styles to this file, and also import other style files */

@import "./src/assets/styles/colors";
@import "./src/assets/styles/grid";
@import "./src/assets/styles/mixins";
@import "./src/assets/styles/normalize";
@import "./src/assets/styles/sizes";
@import "./src/assets/styles/typography";

* {
  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $eerie-black;
    border: 1px solid $green;
  }

  &::-webkit-scrollbar-corner {
    background-color: $eerie-black;
  }
}

html,
body {
  font-family: $main-font-family;
  height: 100%;
  background-color: $background;
}

#root {
  height: 100%;
}
